import React, { useRef, useState, FormEvent } from "react";
import emailjs from "@emailjs/browser";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";

interface Errors {
  user_name?: string;
  user_email?: string;
  user_phone?: string;
  message?: string;
}

export const ContactForm: React.FC = () => {
  const form = useRef<HTMLFormElement>(null);
  const [statusMessage, setStatusMessage] = useState<string>(""); // Status message
  const [errors, setErrors] = useState<Errors>({}); // Error messages

  // Email validation function
  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Phone validation function (allows numbers, spaces, dashes, and +, but must be at least 10 digits)
  const validatePhone = (phone: string): boolean => {
    const phoneRegex = /^[0-9\s\-+()]+$/;
    return phoneRegex.test(phone) && phone.replace(/\D/g, "").length >= 10;
  };

  // Send email via EmailJS
  const sendEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!form.current) return;

    const formData = new FormData(form.current);
    const userName = formData.get("user_name") as string;
    const userEmail = formData.get("user_email") as string;
    const userPhone = formData.get("user_phone") as string;
    const message = formData.get("message") as string;

    // Basic validation
    const newErrors: Errors = {};
    if (!userName) newErrors.user_name = "Името е задължително.";
    if (!userEmail) newErrors.user_email = "Изисква се имейл.";
    if (!userPhone) newErrors.user_phone = "Изисква се телефонен номер.";
    if (!validateEmail(userEmail))
      newErrors.user_email = "Моля, въведете валиден имейл.";
    if (!validatePhone(userPhone))
      newErrors.user_phone = "Моля, въведете валиден телефонен номер.";

    if (!message) newErrors.message = "Изисква се съобщение.";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors); // Display validation errors
      return;
    }

    // Clear any existing errors
    setErrors({});

    // Send form via EmailJS
    emailjs
      .sendForm(
        "service_uep8uzn",
        "template_4i7vy5g",
        form.current,
        "4YdzyKgtycA0FULHe"
      )
      .then(
        (result) => {
          console.log(result.text);
          setStatusMessage("Съобщението е изпратено успешно!");
          form.current?.reset(); // Reset form after successful submission
        },
        (error) => {
          console.log(error.text);
          setStatusMessage(
            "Неуспешно изпращане на съобщението. Моля, опитайте отново."
          );
        }
      );
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>
        Комплекс за възрастни хора "Слънчев Дом - М"
      </h1>
      <p>
        <strong>Не се колебайте да ни зададете въпросите си.</strong>
        <br></br>
        Разполагаме с екип от специалисти, които ще отговорят на всяко ваше
        запитване. <br></br>При посещение на място, ще ви въведем в атмосферата
        на Дома.
      </p>
      <div className={styles.horizontalLine}></div>

      <div className={styles.phone}>
        <p>телефон:</p>
        <a href="tel:0896 632 223" className={styles.phoneLink}>
          0896 632 223
        </a>
      </div>
      <p>
        Управител: <strong>Мариета Речева</strong>
      </p>
      <form ref={form} onSubmit={sendEmail} className={styles.form}>
        <input
          type="text"
          name="user_name"
          placeholder="Име"
          className={styles.input}
        />
        {errors.user_name && <p className={styles.error}>{errors.user_name}</p>}

        <input
          type="email"
          name="user_email"
          placeholder="Email"
          className={styles.input}
        />
        {errors.user_email && (
          <p className={styles.error}>{errors.user_email}</p>
        )}

        <input
          type="tel"
          name="user_phone"
          placeholder="Телефон"
          className={styles.input}
        />
        {errors.user_phone && (
          <p className={styles.error}>{errors.user_phone}</p>
        )}

        <textarea
          name="message"
          placeholder="Съобщение"
          className={styles.textarea}
        />
        {errors.message && <p className={styles.error}>{errors.message}</p>}

        <input type="submit" value="Изпрати" className={styles.submitButton} />
      </form>

      {statusMessage && <p className={styles.status}>{statusMessage}</p>}
      <p>
        Като изпратите формата, вие се съгласявате с нашата{" "}
        <Link to="/privacy-policy">Политика за поверителност</Link>.
      </p>
    </div>
  );
};
