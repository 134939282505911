import { useState, useEffect } from "react";
import styles from "./index.module.scss";

const images = [
  "https://storage.googleapis.com/slanchevdom/home/optimize_home1.JPG",
  "https://storage.googleapis.com/slanchevdom/home/optimize_home2.JPG",
  "https://storage.googleapis.com/slanchevdom/home/optimize_home5.jpg",
  "https://storage.googleapis.com/slanchevdom/home/optimize_home4.JPG",
];

const Part1 = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Function to preload images
  const preloadImages = (imageArray: string[]) => {
    imageArray.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  };

  // Preload images on initial render
  useEffect(() => {
    preloadImages(images);
  }, []);

  useEffect(() => {
    const changeImage = () => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentImageIndex(nextImageIndex);
        setNextImageIndex((nextImageIndex + 1) % images.length);
        setIsTransitioning(false);
      }, 1000); // Adjust this duration to match your CSS transition time
    };
    const interval = setInterval(changeImage, 5000); // change image every 5 seconds
    return () => clearInterval(interval); // clean up the interval
  }, [nextImageIndex]);

  return (
    <div className={styles.page}>
      <div
        className={`${styles.background} ${styles.currentImage}`}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.2)), url(${images[currentImageIndex]})`,
        }}
      />
      <div
        className={`${styles.background} ${styles.nextImage} ${
          isTransitioning ? styles.fadeIn : ""
        }`}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.2)), url(${images[nextImageIndex]})`,
        }}
      />
      <div className={styles.container}>
        <h1>
          Дом за <br></br>възрастни хора
        </h1>
        {/* <p>
          Предлага културни мероприятия, излети, посещение на манастири и др.
          забележителности, благодарение на което, нашите пациенти релаксират и
          разнообразяват ежедневието
        </p> */}
      </div>
    </div>
  );
};

export default Part1;
