// PrivacyPolicy.tsx
import React from "react";
import styles from "./index.module.scss";
import { Helmet } from "react-helmet";

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Политика за поверителност - Слънчев Дом</title>
        <meta
          name="description"
          content="Прочетете нашата политика за поверителност, за да научите как събираме, използваме и защитаваме вашите лични данни в Слънчев Дом."
        />
        <link
          rel="canonical"
          href="https://www.slanchevdom.com/privacy-policy"
        />

        <meta
          property="og:title"
          content="Политика за поверителност - Слънчев Дом"
        />
        <meta
          property="og:description"
          content="Разберете как Слънчев Дом събира и обработва вашите лични данни съгласно нашата политика за поверителност."
        />
        <meta
          property="og:url"
          content="https://www.slanchevdom.com/privacy-policy"
        />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Политика за поверителност - Слънчев Дом"
        />
        <meta
          name="twitter:description"
          content="Научете повече за политиката за поверителност на Слънчев Дом и как защитаваме вашите лични данни."
        />

        <meta
          name="keywords"
          content="политика за поверителност, защита на лични данни, лични данни Слънчев Дом, поверителност, GDPR"
        />
      </Helmet>
      <div className={styles.container}>
        <h1 className={styles.header}>Политика за поверителност</h1>
        <p className={styles.updatedDate}>
          Дата на последна актуализация: 18.10.2024 г.
        </p>

        <h2>1. Каква информация събираме?</h2>
        <p>
          Когато използвате нашия уебсайт и попълвате контактната форма, ние
          събираме следната лична информация:
        </p>
        <ul className={styles.list}>
          <li>
            <strong>Име</strong>: За да можем да се обърнем към вас.
          </li>
          <li>
            <strong>Имейл адрес</strong>: За да ви отговорим.
          </li>
          <li>
            <strong>Телефонен номер</strong>: Ако предоставите такъв, за връзка
            с вас.
          </li>
          <li>
            <strong>Съобщение</strong>: Информацията, която изпращате чрез
            формата.
          </li>
        </ul>

        <h2>2. Как използваме вашата информация?</h2>
        <p>
          Ние използваме събраната информация единствено за целите на отговаряне
          на вашите запитвания и подобряване на нашите услуги. Ние{" "}
          <strong>не споделяме</strong> вашата лична информация с трети страни,
          освен ако това не е необходимо за спазването на законови задължения.
        </p>

        <h2>3. Вградени видеоклипове от YouTube</h2>
        <p>
          Нашият уебсайт включва вградени видеоклипове от{" "}
          <strong>YouTube</strong>. YouTube може да събира данни за вашето
          поведение, включително чрез бисквитки. Можете да научите повече за{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Политиката за поверителност на Google
          </a>
          .
        </p>

        <h2>4. Бисквитки</h2>
        <p>
          Нашият сайт може да използва бисквитки за подобряване на
          потребителското изживяване. Можете да настроите браузъра си така, че
          да отказва бисквитки. Имайте предвид, че някои части от нашия сайт
          може да не функционират правилно без бисквитки.
        </p>

        <h2>5. Сигурност на данните</h2>
        <p>
          Ние полагаме усилия да защитим вашата лична информация чрез подходящи
          технически и организационни мерки.
        </p>

        <h2>6. Вашите права</h2>
        <p>
          Имате право на достъп, корекция, изтриване и ограничаване на
          обработката на вашите данни.
        </p>

        <h2>7. Промени в тази Политика</h2>
        <p>
          Ние си запазваме правото да актуализираме тази Политика за
          поверителност по всяко време.
        </p>

        <h2>8. Използване на Google Analytics</h2>
        <p>
          Нашият уебсайт използва Google Analytics, услуга за уеб анализ,
          предоставяна от Google, Inc. Google Analytics използва "бисквитки", за
          да анализира как потребителите използват сайта. Информацията,
          генерирана от бисквитката за вашето използване на сайта (включително
          вашия IP адрес), ще бъде прехвърлена на сървъри на Google в САЩ и
          съхранявана там.
          <br />
          Можете да откажете използването на бисквитки чрез настройките на вашия
          браузър. Моля, имайте предвид, че това може да повлияе на някои
          функции на нашия уебсайт. За повече информация как Google обработва
          данни, можете да се запознаете с{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Политиката за поверителност на Google
          </a>
          .
        </p>

        <h2>9. Контакт с нас</h2>
        <p>
          Ако имате въпроси относно тази Политика за поверителност, моля
          свържете се с нас на:
        </p>
        <ul className={styles.list}>
          <li>
            Имейл: <b>marieta.familia@abv.bg</b>
          </li>
          <li>
            Телефон: <b>0896 632 223</b>
          </li>
        </ul>
      </div>
    </>
  );
};

export default PrivacyPolicy;
