import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaBars, FaTimes } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import styles from "./index.module.scss";

const MobileNavigation = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.classList.add(styles.overflowHidden);
    } else {
      document.body.classList.remove(styles.overflowHidden);
    }

    return () => {
      document.body.classList.remove(styles.overflowHidden);
    };
  }, [isSidebarOpen]);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div className={styles.navbarIcons} onClick={toggleSidebar}>
        {isSidebarOpen ? <FaTimes /> : <FaBars />}
      </div>
      <AnimatePresence>
        {isSidebarOpen && (
          <motion.div
            className={styles.container}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "100%" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className={styles.content}>
              <NavLink onClick={toggleSidebar} to="/">
                НАЧАЛО
              </NavLink>

              <NavLink onClick={toggleSidebar} to="/about">
                ЗА НАС
              </NavLink>

              {/* <NavLink onClick={toggleSidebar} to="/services">
                ПАКЕТ ДЕЙНОСТИ
              </NavLink> */}

              <NavLink onClick={toggleSidebar} to="/gallery">
                ГАЛЕРИЯ
              </NavLink>

              <NavLink onClick={toggleSidebar} to="/lulin">
                ЛЮЛИН
              </NavLink>

              <NavLink onClick={toggleSidebar} to="/mirovqne">
                МИРОВЯНЕ
              </NavLink>

              <NavLink onClick={toggleSidebar} to="/contact-us">
                КОНТАКТИ
              </NavLink>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileNavigation;
