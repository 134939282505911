import { MdPhone } from "react-icons/md";
import styles from "./index.module.scss";

const CallButton = () => {
  return (
    <a href="tel:0896 632 223" className={styles.callButton}>
      <MdPhone className={styles.icon} />
    </a>
  );
};

export default CallButton;
