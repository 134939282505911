import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { ContactForm } from "../common/contactForm";
import CallButton from "../common/callButton";

const Layout = () => {
  return (
    <div>
      <Header />
      <Outlet />
      <ContactForm />
      <Footer />
      <CallButton />
    </div>
  );
};

export default Layout;
