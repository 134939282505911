import { Helmet } from "react-helmet";
import styles from "./index.module.scss";

const Location2 = () => {
  return (
    <>
      <Helmet>
        <title>Слънчев Дом - Люлин: Бутиков дом с гледка към Витоша</title>
        <meta
          name="description"
          content="Бутиков дом за възрастни хора в София, Люлин-4. Стилен дизайн, красива градина и лесен достъп с градски транспорт. Разгледайте снимките и видеото."
        />
        <link rel="canonical" href="https://www.slanchevdom.com/lulin" />

        <meta
          property="og:title"
          content="Слънчев Дом - Люлин: Бутиков дом с гледка към Витоша"
        />
        <meta
          property="og:description"
          content="Разгледайте бутиковия дом за възрастни хора в София, Люлин-4. С модерни удобства, красива градина и гледка към Витоша."
        />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/slanchevdom/home/optimize_home1.JPG"
        />
        <meta property="og:url" content="https://www.slanchevdom.com/lulin" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Слънчев Дом - Люлин: Бутиков дом с гледка към Витоша"
        />
        <meta
          name="twitter:description"
          content="Бутиков дом за възрастни хора в София, Люлин-4. Разгледайте снимки и видео от дома и неговата прекрасна локация."
        />
        <meta
          name="twitter:image"
          content="https://storage.googleapis.com/slanchevdom/home/optimize_home1.JPG"
        />

        <meta
          name="keywords"
          content="дом за възрастни хора Люлин, хоспис София, бутиков дом, гледка към Витоша, старчески дом Люлин, старчески дом София"
        />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.image}></div>
        <div className={styles.adres}>
          <h3>
            гр.София ж.к Люлин-4 ул.Ицках Грациани №1 <br></br>
            <span>(До 9-то РПУ)</span>
          </h3>
        </div>
        <div className={styles.text}>
          <p>
            Бутиков дом, направен със стил и качество, който отговаря на
            най-високите Ви изисквания!<br></br>С изглед към Люлин планина и
            Витоша.
            <br></br>Бутикова градина, с неповторим дизайн! Живописна
            беседка,където да прекарвате уютно, времето с Вашите близки.{" "}
            <br></br>
            Удобна комуникация с градски транспорт.
          </p>
          <h2>Легловата база на Дом 4 разполага с 52 места.</h2>
        </div>

        <div className={styles.videoWrap}>
          <iframe
            src="https://www.youtube.com/embed/DGzJYPzz-rQ?si=Tn_CGBoo_GPUkRma"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>

        <div className={styles.imageGrid}>
          <div className={styles.imageItem}>
            <img
              src="https://storage.googleapis.com/slanchevdom/lulin/lulin1.JPG"
              alt="Hospice care 1"
            />
          </div>
          <div className={styles.imageItem}>
            <img
              src="https://storage.googleapis.com/slanchevdom/lulin/lulin2.JPG"
              alt="Hospice care 2"
            />
          </div>
          <div className={styles.imageItem}>
            <img
              src="https://storage.googleapis.com/slanchevdom/lulin/lulin3.JPG"
              alt="Hospice care 3"
            />
          </div>
          <div className={styles.imageItem}>
            <img
              src="https://storage.googleapis.com/slanchevdom/lulin/lulin4.JPG"
              alt="Hospice care 4"
            />
          </div>
          <div className={styles.imageItem}>
            <img
              src="https://storage.googleapis.com/slanchevdom/lulin/lulin5.JPG"
              alt="Hospice care 5"
            />
          </div>
          <div className={styles.imageItem}>
            <img
              src="https://storage.googleapis.com/slanchevdom/gallery/gal53.JPG"
              alt="Hospice care 6"
            />
          </div>
          <div className={styles.imageItem}>
            <img
              src="https://storage.googleapis.com/slanchevdom/lulin/lulin7.JPG"
              alt="Hospice care 5"
            />
          </div>
          <div className={styles.imageItem}>
            <img
              src="https://storage.googleapis.com/slanchevdom/lulin/lulin8.JPG"
              alt="Hospice care 6"
            />
          </div>
        </div>

        <div>
          <iframe
            title="sl map"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d732.8173782579897!2d23.237212269658084!3d42.719185983490554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDQzJzA5LjEiTiAyM8KwMTQnMTYuMyJF!5e0!3m2!1sbg!2sbg!4v1728241979468!5m2!1sbg!2sbg"
            style={{ border: "0", width: "100%", height: "400px" }}
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Location2;
