import styles from "./index.module.scss";

const Part5Text = () => {
  return (
    <div className={styles.container}>
      <p>
        <strong>“Слънчев дом - М”</strong>
        <br></br>
        разполага с леглова база за 300 човека
        <ul>
          <li> 140 двойни стаи</li>
          <li> 8 единични стаи</li>
          <li> 5 тройни стаи</li>
        </ul>
      </p>
      <p>
        <strong>Всяка стая разполага със: </strong>
        <ul>
          <li>Собствен санитарен възел</li>
          <li>Тераса</li>
          <li>Звънчева система</li>
          <li>Телевизор</li>
          <li>Wi-Fi</li>
        </ul>
      </p>
      <p>
        <strong>Допълнителни услуги:</strong>
        <ul>
          <li>Възможност за поставяне на антидекубитален дюшек</li>
          <li>Ортопедични и медицински легла</li>
          <li>
            Рехабилитационна програма разработена според нуждите на пациента
          </li>
          <li>
            Болногледачи и санитари, които се грижат денонощно за комфорта,
            естетичния вид и хигиенните нужди на домуващите спрямо тяхното
            физическо и ментално състояние
          </li>
        </ul>
      </p>
    </div>
  );
};

export default Part5Text;
