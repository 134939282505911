import styles from "./index.module.scss";
import { Link } from "react-router-dom";

const Part6Locations = () => {
  return (
    <div className={styles.container}>
      <h1>Локации</h1>
      <div className={styles.location}>
        <div className={styles.line}></div>
        <div className={styles.text}>
          <h3>с.Мировяне</h3>
        </div>
        <div className={styles.line}></div>
      </div>
      <div style={{ marginBottom: "34px" }} className={styles.location}>
        <div className={styles.line}></div>
        <div className={styles.text}>
          <h5>ул.Мировско шосе 527</h5>
        </div>
        <div className={styles.line}></div>
      </div>

      <div className={styles.imageContainer}>
        <img
          src="https://storage.googleapis.com/slanchevdom/mirovqne/mirovqne8.JPG"
          alt="sl"
        />
        <Link to="/mirovqne">
          <button className={styles.hoverButton}>Виж повече </button>
        </Link>
      </div>

      <div style={{ marginTop: "34px" }} className={styles.location}>
        <div className={styles.line}></div>
        <div className={styles.text}>
          <h3>град София жк. Люлин 4</h3>
        </div>
        <div className={styles.line}></div>
      </div>

      <div className={styles.location}>
        <div className={styles.line}></div>
        <div className={styles.text}>
          <h5>ул.Ицках Грациани №1 </h5>
        </div>
        <div className={styles.line}></div>
      </div>

      <div style={{ marginBottom: "34px" }} className={styles.location}>
        <div className={styles.line}></div>
        <div className={styles.text}>
          <h5 style={{ fontSize: "18px" }}>(До 9-то РПУ)</h5>
        </div>
        <div className={styles.line}></div>
      </div>

      <div className={styles.imageContainer}>
        <img
          src="https://storage.googleapis.com/slanchevdom/home/optimize_home1.JPG"
          alt="sl"
        />

        <Link to="/lulin">
          <button className={styles.hoverButton}>Виж повече </button>
        </Link>
      </div>
    </div>
  );
};

export default Part6Locations;
