import { Helmet } from "react-helmet";
import Part1 from "./Part1";
import Part2 from "./Part2";
import Part4 from "./Part4";
import Part5Text from "./Part5Text";
import Part6Locations from "./Part6Locations";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          Дом За Възрастни Хора Хоспис Слънчев Дом 24/7 Медицинска Грижа
        </title>
        <meta
          name="description"
          content="Слънчев дом М - Хоспис за възрастни хора с 24/7 медицинска грижа, рехабилитация и индивидуални грижи. Над 10 години опит в София."
        />
        <link rel="canonical" href="https://www.slanchevdom.com/" />

        <meta
          property="og:title"
          content="Дом За Възрастни Хора Хоспис Слънчев Дом 24/7 Медицинска Грижа"
        />
        <meta
          property="og:description"
          content="Хоспис Слънчев дом предлага 24/7 медицинска помощ, рехабилитация и персонализирани грижи за възрастни хора в София."
        />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/slanchevdom/home/optimize_home1.JPG"
        />
        <meta property="og:url" content="https://www.slanchevdom.com/" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Дом За Възрастни Хора Хоспис Слънчев Дом 24/7 Медицинска Грижа"
        />
        <meta
          name="twitter:description"
          content="24/7 медицинска грижа и рехабилитация за възрастни хора в Хоспис Слънчев дом."
        />
        <meta
          name="twitter:image"
          content="https://storage.googleapis.com/slanchevdom/home/optimize_home1.JPG"
        />

        <meta
          name="keywords"
          content="дом за възрастни хора, хоспис, 24/7 медицинска грижа, рехабилитация, медицински грижи, София"
        />
      </Helmet>

      <Part1 />
      <Part2 />
      <Part5Text />
      <Part6Locations />
      <Part4 />
    </>
  );
};

export default Home;
