import styles from "./index.module.scss";
import {
  FaUserMd,
  FaBell,
  FaUtensils,
  FaHospitalUser,
  FaHeartbeat,
  FaUserNurse,
  FaHome,
  FaWalking,
  FaHandshake,
} from "react-icons/fa";
import { MdAssistWalker, MdLocalLaundryService } from "react-icons/md";
import { BiSolidCctv } from "react-icons/bi";
import { FaBrain } from "react-icons/fa";
import { GiMedicalDrip } from "react-icons/gi";
import { TbDisabled } from "react-icons/tb";

const Part4 = () => {
  return (
    <>
      <div>
        <div className={styles.infoBoxSingle}>
          <div>
            <FaHome />
          </div>
          <p>
            Настаняването става в подходяща зона, в зависимост от състоянието на
            пациента.
            <br></br>Всеки се нуждае от специфични грижи и внимание. <br></br>
            Благодарение на големия капацитет от хора и сгради, пациентите са
            разделени на зони.
          </p>
          <ul>
            <li>Адекватна зона</li>
            <li>Смесена зона- адекватна лека деменция</li>
            <li>Старческа деменция</li>
            <li>
              Лежащи-с нужда от 100% медицинска грижа и активна рехабилитация
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.infoBox}>
          <div>
            <FaUserMd />
          </div>
          <p>24/7 обслужване и наблюдение от медицински персонал</p>
        </div>

        <div className={styles.infoBox}>
          <div>
            <FaHospitalUser />
          </div>
          <p>Ежедневна лекарска визитация</p>
        </div>

        <div className={styles.infoBox}>
          <div>
            <FaWalking />
          </div>
          <p>Административна визитация 2 пъти в седмицата</p>
        </div>

        <div className={styles.infoBox}>
          <div>
            <FaHeartbeat />
          </div>
          <p>
            Консултация със специалисти при нужда
            <ul>
              <li>Кардиолог</li>
              <li>Невролог</li>
              <li>Психиатър</li>
              <li>Ендокринолог</li>
              <li>Дерматолог</li>
              <li>Хирург и др.</li>
            </ul>
          </p>
        </div>

        <div className={styles.infoBox}>
          <div>
            <MdAssistWalker />
          </div>
          <p>Рехабилитатори, Кинезитерапевти и Физиотерапевти</p>
        </div>

        <div className={styles.infoBox}>
          <div>
            <FaHandshake />
          </div>
          <p>Социален работник и активни социални дейности</p>
        </div>

        <div className={styles.infoBox}>
          <div>
            <FaBrain />
          </div>
          <p>Психологическа помощ за адаптация на домуващите</p>
        </div>

        <div className={styles.infoBox}>
          <div>
            <FaUserNurse />
          </div>
          <p>Санитарно обслужване</p>
        </div>

        <div className={styles.infoBox}>
          <div>
            <TbDisabled />
          </div>
          <p>Болногледачи - 100% хигиенни и ежедневни грижи</p>
        </div>

        <div className={styles.infoBox}>
          <div>
            <FaHospitalUser />
          </div>
          <p>Лечение на декубитални рани от медицински специалисти</p>
        </div>

        <div className={styles.infoBox}>
          <div>
            <GiMedicalDrip />
          </div>
          <p>24/7 Неотложна медицинска грижа</p>
        </div>

        <div className={styles.infoBox}>
          <div>
            <FaUtensils />
          </div>
          <p>Тристепенно меню, с възможност за диетично и вегетарианско меню</p>
        </div>

        <div className={styles.infoBox}>
          <div>
            <MdLocalLaundryService />
          </div>
          <p>
            Пълно хигиенно обслужване - пране на постелъчно, лично бельо и дрехи
          </p>
        </div>

        <div className={styles.infoBox}>
          <div>
            <FaBell />
          </div>
          <p>Звънчева система за повикване на медицински персонал при нужда</p>
        </div>

        <div className={styles.infoBox}>
          <div>
            <BiSolidCctv />
          </div>
          <p>Видеонаблюдение и мониторинг</p>
        </div>
      </div>
    </>
  );
};

export default Part4;
