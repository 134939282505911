import { Helmet } from "react-helmet";
import styles from "./index.module.scss";

const Location1 = () => {
  return (
    <>
      <Helmet>
        <title>
          Слънчев Дом - Мировяне: Чист въздух и спокойствие в красива обстановка
        </title>
        <meta
          name="description"
          content="Дом за възрастни хора в с. Мировяне. Разположен на 5 дка с чист въздух и гледка към Стара планина. Красиви градини и спокойствие за домуващите."
        />
        <link rel="canonical" href="https://www.slanchevdom.com/mirovqne" />

        <meta
          property="og:title"
          content="Слънчев Дом - Мировяне: Чист въздух и спокойствие в красива обстановка"
        />
        <meta
          property="og:description"
          content="Трите сгради на хоспис Слънчев Дом в с. Мировяне предлагат уют, тишина и спокойствие сред красива природа и градини. Разположени на 5 дка с гледка към Стара планина."
        />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/slanchevdom/home/optimize_home1.JPG"
        />
        <meta
          property="og:url"
          content="https://www.slanchevdom.com/mirovqne"
        />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Слънчев Дом - Мировяне: Чист въздух и спокойствие в красива обстановка"
        />
        <meta
          name="twitter:description"
          content="Дом за възрастни хора в с. Мировяне, разположен сред красиви градини с изглед към Стара планина. Спокойствие и удобство далеч от градската среда."
        />
        <meta
          name="twitter:image"
          content="https://storage.googleapis.com/slanchevdom/home/optimize_home1.JPG"
        />

        <meta
          name="keywords"
          content="дом за възрастни хора Мировяне, хоспис, чист въздух, спокойствие, старчески дом Мировяне, релакс, тишина"
        />
      </Helmet>

      <div className={styles.container}>
        <div className={styles.image}></div>
        <div className={styles.adres}>
          <h3>с.Мировяне ул.Мировско шосе №527</h3>
        </div>
        <div className={styles.text}>
          <p>
            Чист въздух, красива гледка и спокойствие от което се нуждаят нашите
            Домуващи, всичко това разположено на 5 дка дворно място. <br></br>
            Трите сгради са сред красива градина<br></br> тишина и спокойствие,
            което е в хармония с представата за релакс и уют. Големи тераси с
            изглед към Стара планина.<br></br>Далеч от градска среда, но в
            удобна комуникация с градски транспорт. <br></br>Параклисът
            Св.Николай Чудотворец, разположен в двора на Дома дава вяра, сила и
            енергия на всеки, който го посети!
          </p>
          <h2>Легловата база на Дом 1, Дом2, Дом3 разполага с 250 места.</h2>
        </div>

        <div className={styles.videoWrap}>
          <iframe
            src="https://www.youtube.com/embed/swH-nsZeHs0?si=DXZ3wfrdX0nAE1_E"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>

        <div className={styles.imageGrid}>
          <div className={styles.imageItem}>
            <img
              src="https://storage.googleapis.com/slanchevdom/mirovqne/mirovqne1.JPG"
              alt="Hospice care 1"
            />
          </div>
          <div className={styles.imageItem}>
            <img
              src="https://storage.googleapis.com/slanchevdom/mirovqne/mirovqne2.JPG"
              alt="Hospice care 2"
            />
          </div>
          <div className={styles.imageItem}>
            <img
              src="https://storage.googleapis.com/slanchevdom/mirovqne/mirovqne3.JPG"
              alt="Hospice care 3"
            />
          </div>
          <div className={styles.imageItem}>
            <img
              src="https://storage.googleapis.com/slanchevdom/mirovqne/mirovqne4.JPG"
              alt="Hospice care 4"
            />
          </div>
          <div className={styles.imageItem}>
            <img
              src="https://storage.googleapis.com/slanchevdom/gallery/gal5.JPG"
              alt="Hospice care 5"
            />
          </div>
          <div className={styles.imageItem}>
            <img
              src="https://storage.googleapis.com/slanchevdom/mirovqne/mirovqne6.JPG"
              alt="Hospice care 6"
            />
          </div>
          <div className={styles.imageItem}>
            <img
              src="https://storage.googleapis.com/slanchevdom/mirovqne/mirovqne7.JPG"
              alt="Hospice care 5"
            />
          </div>
          <div className={styles.imageItem}>
            <img
              src="https://storage.googleapis.com/slanchevdom/mirovqne/mirovqne8.JPG"
              alt="Hospice care 6"
            />
          </div>
        </div>

        <div>
          <iframe
            title="sl map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d23425.8639748579!2d23.309162!3d42.783424!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa91814d730413%3A0x2020c60aa56b76c0!2z0KHQu9GK0L3Rh9C10LIg0LTQvtC8!5e0!3m2!1sbg!2sbg!4v1706626348415!5m2!1sbg!2sbg"
            style={{ border: "0", width: "100%", height: "400px" }}
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Location1;
