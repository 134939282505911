import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./index.module.scss";
import logoMobile from "../../../assets/images/logo.png";
import MobileNavigation from "./mobile";

const Header = () => {
  const location = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (location.pathname !== "/") {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      location.pathname === "/" && setActive(window.scrollY > 80);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  return (
    <nav className={active ? styles.containerActive : styles.container}>
      <div className={styles.leftLink}>
        <div className={active ? styles.linkActive : styles.link}>
          <NavLink
            className={({ isActive }) =>
              isActive && active
                ? styles.activeDark
                : isActive
                ? styles.activeBright
                : ""
            }
            to="/"
          >
            НАЧАЛО
          </NavLink>
        </div>
        <div className={active ? styles.linkActive : styles.link}>
          <NavLink
            className={({ isActive }) =>
              isActive && active
                ? styles.activeDark
                : isActive
                ? styles.activeBright
                : ""
            }
            to="/about"
          >
            ЗА НАС
          </NavLink>
        </div>
        <div className={active ? styles.linkActive : styles.link}>
          <NavLink
            className={({ isActive }) =>
              isActive && active
                ? styles.activeDark
                : isActive
                ? styles.activeBright
                : ""
            }
            to="/gallery"
          >
            ГАЛЕРИЯ
          </NavLink>
        </div>
      </div>

      <NavLink to="/" className={styles.navLogo}>
        <img src={logoMobile} alt="Logo" className={styles.logoImage} />
      </NavLink>

      <MobileNavigation />

      <div className={styles.rightLink}>
        <div className={active ? styles.linkActive : styles.link}>
          <NavLink
            className={({ isActive }) =>
              isActive && active
                ? styles.activeDark
                : isActive
                ? styles.activeBright
                : ""
            }
            to="/lulin"
          >
            ЛЮЛИН
          </NavLink>
        </div>
        <div className={active ? styles.linkActive : styles.link}>
          <NavLink
            className={({ isActive }) =>
              isActive && active
                ? styles.activeDark
                : isActive
                ? styles.activeBright
                : ""
            }
            to="/mirovqne"
          >
            МИРОВЯНЕ
          </NavLink>
        </div>
        <div className={active ? styles.linkActive : styles.link}>
          <NavLink
            className={({ isActive }) =>
              isActive && active
                ? styles.activeDark
                : isActive
                ? styles.activeBright
                : ""
            }
            to="/contact-us"
          >
            КОНТАКТИ
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Header;
