import { Link, Route, Routes } from "react-router-dom";
import "./styles/index.scss";
import Layout from "./components/layout";
import Home from "./components/pages/home";
import About from "./components/pages/about";
import Gallery from "./components/pages/gallery";
import ContactUs from "./components/pages/contact-us";
import Location1 from "./components/pages/mirovqne";
import Location2 from "./components/pages/lulin";
import PrivacyPolicy from "./components/pages/privacyPolicy";

import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import PrivacyPolicyEn from "./components/pages/privacyPolicyEn";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

const analyticsMeasurementId = "G-25GEXF8TV3";
const adsMeasurementId = "AW-11486299103";

function App() {
  const [analyticsInitialized, setAnalyticsInitialized] = useState(false);

  function initializeAnalyticsAndAds() {
    ReactGA.initialize([
      {
        trackingId: analyticsMeasurementId,
      },
      {
        trackingId: adsMeasurementId,
      },
    ]);
    ReactGA.send("pageview");
  }

  useEffect(() => {
    const consent = getCookieConsentValue();
    if (consent === "true" && !analyticsInitialized) {
      initializeAnalyticsAndAds();
      setAnalyticsInitialized(true);
    }
  }, [analyticsInitialized]);

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Приеми"
        declineButtonText="Откажи"
        enableDeclineButton
        cookieName="cookieConsent"
        style={{ zIndex: 99999 }}
        buttonStyle={{ backgroundColor: "#4CAF50", color: "white" }}
        onAccept={() => {
          // Initialize Google Analytics when the user accepts cookies
          if (!analyticsInitialized) {
            initializeAnalyticsAndAds();
            ReactGA.send("pageview");
            setAnalyticsInitialized(true);
          }
        }}
      >
        Този уебсайт използва бисквитки за подобряване на потребителското
        изживяване и за аналитични цели.{" "}
        <Link to="/privacy-policy" style={{ color: "white" }}>
          Прочетете повече
        </Link>
      </CookieConsent>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/mirovqne" element={<Location1 />} />
          <Route path="/lulin" element={<Location2 />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/privacy-policy-en" element={<PrivacyPolicyEn />} />
          <Route
            path="*"
            element={
              <h1 style={{ marginTop: "120px", textAlign: "center" }}>
                404 - Not Found
              </h1>
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;
