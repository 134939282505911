import styles from "./index.module.scss";
import global from "../../../styles/global.module.scss";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>
          За Нас - Слънчев Дом: Най-големият дом за възрастни хора в България
        </title>
        <meta
          name="description"
          content="Слънчев Дом - М: Най-големият дом за възрастни хора в България. От 2015 г. предоставяме грижи и спокойствие на вашите близки. Доверете ни се за достойни старини."
        />
        <link rel="canonical" href="https://www.slanchevdom.com/about" />

        <meta
          property="og:title"
          content="За Нас - Слънчев Дом: Най-големият дом за възрастни хора в България"
        />
        <meta
          property="og:description"
          content="Слънчев Дом - М: Най-големият дом за възрастни хора в България. От 2015 г. предоставяме грижи и спокойствие на вашите близки. Доверете ни се за достойни старини."
        />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/slanchevdom/home/optimize_home1.JPG"
        />
        <meta property="og:url" content="https://www.slanchevdom.com/about" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="За Нас - Слънчев Дом: Най-големият дом за възрастни хора в България"
        />
        <meta
          name="twitter:description"
          content="Слънчев Дом - М: От 2015 г. се грижим за вашите близки с професионализъм и внимание. Присъединете се към нашето семейство от доволни клиенти."
        />
        <meta
          name="twitter:image"
          content="https://storage.googleapis.com/slanchevdom/home/optimize_home1.JPG"
        />

        <meta
          name="keywords"
          content="дом за възрастни хора, хоспис, грижи за възрастни хора, Слънчев дом, най-големият дом в България, спокойствие, старини"
        />
      </Helmet>
      <div className={styles.aboutUs}>
        <div className={global.section}>
          <div className={global.sectionTitle}>
            <h1>За Нас</h1>
          </div>
        </div>

        <div className={styles.content}>
          <h2>
            За достойни старини на Вас и Вашите близки -{" "}
            <strong>ДОВЕРЕТЕ НИ СЕ!</strong>
          </h2>
          <p>
            <h2>
              Първият най-голям дом на територията на България! <br></br>
              “Слънчев дом - М EOOD”
            </h2>
            Разположен в 5 дка парк в спокойствие и уют отвори врати <br></br>
            <strong>Дом 1 през 2015 г.</strong> <br></br>
            Вдъхновени от желанието да помагаме на Вас и Вашите близки!
            <br></br>
            Вашето търсене, препоръките и добрите резултати, ни накара да
            продължаваме напред. <br></br>
            <strong>2018 г. отвори врати Дом 2.</strong>
            <br></br> И продължихме напред<br></br>
            <strong>2022 г. отвори врати Дом 3.</strong>
            <br></br>
            Подпомагани от Вашите благодарствени думи, препоръките, които
            продължават и до днес, ни направиха <br></br>
            <strong>Дом водещ в България!</strong>
          </p>
          <h3>Нашите спомени събрани през годините в клипове</h3>
          <iframe
            src="https://www.youtube.com/embed/dVrFVkjv694?si=vQv9Q9vRND5Zie46"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            loading="lazy"
          ></iframe>
          <p>
            Благодарение на нашите клиенти, които ни се довериха през годините,
            на отговорен и дисциплиниран персонал, ние от <br></br>
            <span className={styles.greenText}>“Слънчев дом-М”</span> <br></br>
            продължаваме да работим за Вас! <br></br>И продължихме напред{" "}
            <br></br>
            <strong>2024г. отвори врати Дом 4</strong> <br></br>
            <br></br>
            <strong>
              Ние сме екип от отговорни специалисти, които безрезервно обичаме
              работата си. <br></br>Хора, които разбират вашите нужди и
              потребности!
              <br></br>
              <span className={styles.greenText}>
                Нашата професионална цел е вашето здраве и спокойствие!
              </span>
            </strong>
          </p>

          <iframe
            src="https://www.youtube.com/embed/ayEEdhoCL_o?si=Khu0-19UXkC-nLs_"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            loading="lazy"
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/58sVCgSrmWA?si=7DFD6gEioyGIcis1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
