import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import styles from "./index.module.scss";
import global from "../../../styles/global.module.scss";

import "react-image-gallery/styles/css/image-gallery.css";
import { Helmet } from "react-helmet";

const images = [
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal1.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal1.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal2.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal2.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal3.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal3.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal4.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal4.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal5.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal5.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal6.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal6.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal7.PNG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal7.PNG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal8.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal8.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal9.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal9.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal10.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal10.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal11.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal11.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal12.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal12.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal13.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal13.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal14.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal14.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal15.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal15.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal16.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal16.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal17.jpg",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal17.jpg",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal18.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal18.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal19.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal19.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal20.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal20.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal21.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal21.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal22.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal22.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal23.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal23.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal24.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal24.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal25.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal25.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal26.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal26.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal27.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal27.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal28.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal28.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal29.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal29.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal30.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal30.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal31.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal31.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal32.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal32.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal33.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal33.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal34.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal34.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal35.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal35.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal36.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal36.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal37.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal37.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal38.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal38.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal39.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal39.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal40.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal40.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal41.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal41.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal42.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal42.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal43.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal43.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal44.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal44.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal45.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal45.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal46.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal46.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal47.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal47.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal48.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal48.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal49.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal49.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal50.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal50.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal51.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal51.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal52.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal52.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal53.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal53.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal54.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal54.JPG",
  },
  {
    original: "https://storage.googleapis.com/slanchevdom/gallery/gal55.JPG",
    thumbnail:
      "https://storage.googleapis.com/slanchevdom/gallery/thumbnail/jpeg-optimizer_gal55.JPG",
  },
];

const MyImageGallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openLightbox = (index: React.SetStateAction<number>) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Галерия - Слънчев Дом: Нашият Дом в Снимки</title>
        <meta
          name="description"
          content="Разгледайте нашата галерия със снимки от хоспис Слънчев Дом. Вижте уютната обстановка и условията, които предлагаме за вашите близки."
        />
        <link rel="canonical" href="https://www.slanchevdom.com/gallery" />

        <meta
          property="og:title"
          content="Галерия - Слънчев Дом: Нашият Дом в Снимки"
        />
        <meta
          property="og:description"
          content="Разгледайте снимки от хоспис Слънчев Дом и вижте условията, които предлагаме за вашите близки."
        />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/slanchevdom/home/optimize_home1.JPG"
        />
        <meta property="og:url" content="https://www.slanchevdom.com/gallery" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Галерия - Слънчев Дом: Нашият Дом в Снимки"
        />
        <meta
          name="twitter:description"
          content="Разгледайте снимки от хоспис Слънчев Дом и вижте условията, които предлагаме за вашите близки."
        />
        <meta
          name="twitter:image"
          content="https://storage.googleapis.com/slanchevdom/home/optimize_home1.JPG"
        />

        <meta
          name="keywords"
          content="галерия Слънчев дом, снимки, хоспис, дом за възрастни хора, условия, снимки на хоспис"
        />
      </Helmet>
      <div>
        <div className={global.section}>
          <div className={global.sectionTitle}>
            <h1>Галерия</h1>
          </div>
        </div>
        <div className={styles.grid}>
          {images.map((image, index) => (
            <img
              key={index}
              src={image.thumbnail}
              alt={`Слънчев Дом ${index}`}
              className={styles.gridImage}
              onClick={() => openLightbox(index)}
            />
          ))}
        </div>

        {isOpen && (
          <div className={styles.lightbox}>
            <ImageGallery
              items={images}
              startIndex={currentIndex}
              showThumbnails={false}
              showPlayButton={false}
              showFullscreenButton={false}
              onSlide={(currentSlide) => setCurrentIndex(currentSlide)}
              additionalClass={styles.customArrow} // Apply the custom arrow color
            />
            <button className={styles.closeButton} onClick={closeLightbox}>
              X
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default MyImageGallery;
