import styles from "./index.module.scss";
import global from "../../../styles/global.module.scss";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>Контакти - Слънчев Дом: Връзка с нас</title>
        <meta
          name="description"
          content="Свържете се с нас: Хоспис Слънчев Дом. Ние сме на разположение за въпроси и консултации. Адреси на нашите локации и имейл за връзка."
        />
        <link rel="canonical" href="https://www.slanchevdom.com/contact-us" />

        <meta
          property="og:title"
          content="Контакти - Слънчев Дом: Връзка с нас"
        />
        <meta
          property="og:description"
          content="Свържете се с Хоспис Слънчев Дом. Ние сме на разположение за въпроси и консултации. Вижте адресите и имейл за контакт."
        />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/slanchevdom/home/optimize_home1.JPG"
        />
        <meta
          property="og:url"
          content="https://www.slanchevdom.com/contact-us"
        />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Контакти - Слънчев Дом: Връзка с нас"
        />
        <meta
          name="twitter:description"
          content="Свържете се с Хоспис Слънчев Дом чрез имейл или посетете нашите локации. Ние сме тук за вашите въпроси."
        />
        <meta
          name="twitter:image"
          content="https://storage.googleapis.com/slanchevdom/home/optimize_home1.JPG"
        />

        <meta
          name="keywords"
          content="контакти Слънчев дом, адрес Слънчев дом, имейл Слънчев дом, хоспис контакти, връзка с нас"
        />
      </Helmet>

      <section className={styles.container}>
        <div className={global.section}>
          <div className={global.sectionTitle}>
            <h1>Контакти</h1>
          </div>
        </div>
        <div className={styles.iframeWrap}>
          <iframe
            src="https://www.youtube.com/embed/9HSIWhBf4bA?si=9lMDdsoY7DC5u217"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>

        <div className={styles.infoContainer}>
          <div className={styles.infoBox}>
            <FaEnvelope />
            <h3>E-mail</h3>
            <a href="mailto:slynchevdom@abv.bg">slynchevdom@abv.bg</a>
          </div>

          <div className={styles.infoBox}>
            <FaPhone />
            <h3>Телефон</h3>
            <a href="tel:0896 632 223"> 0896 632 223</a>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
