import styles from "./index.module.scss";
import { Link, NavLink } from "react-router-dom";
import logo2 from "../../../assets/images/logo.png";
import { FaPhone, FaFacebook, FaYoutube, FaEnvelope } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerRow}>
        <div className={styles.logoAndLinks}>
          <img src={logo2} alt="Logo" className={styles.logo} />
        </div>

        <div className={styles.contactAndSocial}>
          <div className={styles.contactInfo}>
            <div className={styles.phone}>
              <FaPhone />
              <a href="tel:0896 632 223"> 0896 632 223</a>
            </div>
            <div className={styles.email}>
              <FaEnvelope />
              <a href="mailto:slynchevdom@abv.bg">slynchevdom@abv.bg</a>
            </div>
          </div>

          <div className={styles.upravitel}>
            <p className={styles.upravitelText}>
              Управител:{" "}
              <p>
                <strong>Мариета Речева</strong>
              </p>
            </p>
          </div>

          <div className={styles.socialLinks}>
            <NavLink target="_blank" to="https://www.facebook.com/sunnyhomebg">
              <FaFacebook style={{ fontSize: "20px" }} />
            </NavLink>
            <NavLink
              target="_blank"
              to="https://www.youtube.com/@user-jv9fp7jy9y"
            >
              <FaYoutube style={{ fontSize: "22px" }} />
            </NavLink>
          </div>
        </div>
      </div>

      <div className={styles.privacyPolicy}>
        <div>
          <p>
            Политика за поверителност {""}
            <Link to="/privacy-policy" className={styles.link}>
              BG
            </Link>
            {""} | {""}
            <Link to="/privacy-policy-en" className={styles.link}>
              EN
            </Link>
          </p>
        </div>

        <p>© 2024 Слънчев Дом. Всички права запазени.</p>
      </div>
    </footer>
  );
};

export default Footer;
