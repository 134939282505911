import styles from "./index.module.scss";

const Part2 = () => {
  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <h3>ДОМ, В КОЙТО ГРИЖАТА, СПОКОЙСТВИЕТО И УЮТА СА НАША ОСНОВНА ЦЕЛ!</h3>
        <p>
          <strong>
            10 години грижи за Вашите близки - години, в които Вие ни се
            доверихте и ни допуснахте във Вашите сърца!
          </strong>
          <br></br>
          Доверието, което изградихме през това време, заедно с грижите, които
          полагахме, и любовта, с която работихме, ни направиха по-силни,
          по-отговорни и по-грижовни!
        </p>
        <p>
          Нашият екип, който неуморно работи през всичките тези години, се
          усъвършенства непрестанно и достигна качество, което напълно отговаря
          на <br></br>
          <strong>Вашите нужди и потребности!</strong>
        </p>
      </div>
      <div className={styles.rightSide}>
        <iframe
          src="https://www.youtube.com/embed/9HSIWhBf4bA?si=9lMDdsoY7DC5u217"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
        <iframe
          src="https://www.youtube.com/embed/RJBjuYjJybM?si=1-ZaKXxvDlP163um"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Part2;
