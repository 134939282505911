import React from "react";
import styles from "./index.module.scss";
import { Helmet } from "react-helmet";

const PrivacyPolicyEn: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Slanchev Dom</title>
        <meta
          name="description"
          content="Read our privacy policy to learn how we collect, use, and protect your personal data at Slanchev Dom."
        />
        <link
          rel="canonical"
          href="https://www.slanchevdom.com/privacy-policy-en"
        />

        <meta property="og:title" content="Privacy Policy - Slanchev Dom" />
        <meta
          property="og:description"
          content="Find out how Slanchev Dom collects and processes your personal data according to our privacy policy."
        />
        <meta
          property="og:url"
          content="https://www.slanchevdom.com/privacy-policy-en"
        />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Privacy Policy - Slanchev Dom" />
        <meta
          name="twitter:description"
          content="Learn more about Slanchev Dom's privacy policy and how we protect your personal data."
        />

        <meta
          name="keywords"
          content="privacy policy, personal data protection, GDPR, Slanchev Dom privacy, data protection"
        />
      </Helmet>
      <div className={styles.container}>
        <h1 className={styles.header}>Privacy Policy</h1>
        <p className={styles.updatedDate}>Last updated: October 18, 2024</p>

        <h2>1. What information do we collect?</h2>
        <p>
          When you use our website and fill out the contact form, we collect the
          following personal information:
        </p>
        <ul className={styles.list}>
          <li>
            <strong>Name</strong>: So we can address you.
          </li>
          <li>
            <strong>Email address</strong>: So we can respond to you.
          </li>
          <li>
            <strong>Phone number</strong>: If provided, so we can contact you.
          </li>
          <li>
            <strong>Message</strong>: The information you send through the form.
          </li>
        </ul>

        <h2>2. How do we use your information?</h2>
        <p>
          We use the information collected solely for the purposes of responding
          to your inquiries and improving our services. We{" "}
          <strong>do not share</strong> your personal information with third
          parties, unless required by law.
        </p>

        <h2>3. Embedded YouTube Videos</h2>
        <p>
          Our website includes embedded videos from <strong>YouTube</strong>.
          YouTube may collect data about your behavior, including through
          cookies. You can learn more by visiting{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google's Privacy Policy
          </a>
          .
        </p>

        <h2>4. Cookies</h2>
        <p>
          Our site may use cookies to enhance the user experience. You can set
          your browser to refuse cookies. Please note that some parts of our
          site may not function properly without cookies.
        </p>

        <h2>5. Data Security</h2>
        <p>
          We strive to protect your personal information through appropriate
          technical and organizational measures.
        </p>

        <h2>6. Your Rights</h2>
        <p>
          You have the right to access, correct, delete, and restrict the
          processing of your data.
        </p>

        <h2>7. Changes to this Policy</h2>
        <p>We reserve the right to update this Privacy Policy at any time.</p>

        <h2>8. Use of Google Analytics</h2>
        <p>
          Our website uses Google Analytics, a web analytics service provided by
          Google, Inc. Google Analytics uses "cookies" to help analyze how users
          use the site. The information generated by the cookie about your use
          of the website (including your IP address) will be transmitted to and
          stored by Google on servers in the United States.
          <br />
          You can refuse the use of cookies by selecting the appropriate
          settings on your browser. Please note that this may affect the
          functionality of some parts of our site. For more information on how
          Google processes data, you can review{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google’s Privacy Policy
          </a>
          .
        </p>

        <h2>9. Contact Us</h2>
        <p>
          If you have any questions regarding this Privacy Policy, please
          contact us at:
        </p>
        <ul className={styles.list}>
          <li>
            Email: <b>marieta.familia@abv.bg</b>
          </li>
          <li>
            Phone: <b>0896 632 223</b>
          </li>
        </ul>
      </div>
    </>
  );
};

export default PrivacyPolicyEn;
